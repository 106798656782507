.list-hold-frame {
  overflow: hidden;
  border: 0.01rem solid var(--font-gray);
}

.set-time-frame {
  display: flex;
  justify-content: space-between;
  align-items: center;
  color: var(--font-black);
  text-decoration-color: var(--font-black);
}

.weekdays {
  color: var(--font-gray);
}

.set-time-frame p {
  display: flex;
  align-items: center;
}

.set-time {
  text-decoration: underline;
  cursor: pointer;
  width: 7rem;
}

.MuiButtonBase-root.MuiAccordionSummary-root {
  background-color: white;
}

.MuiAccordionDetails-root {
  background-color: rgba(250, 250, 250, 1);
}

@import "variables";

.fullHeight{
  min-height: calc(100vh - 60px);
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.instructionsWrapper{
  ul{
    li{
      margin: 6px 0;
    }
  }
}

.header {
  display: flex;
  align-items: center;
  justify-content: space-between;
  height: 6rem;
  background: transparent;
  padding: 0 24px;
  position:absolute;
  right:0;
  width: -webkit-fill-available; 
}

.footer{
  background: $primary-color;
  display: flex;
  align-items: center;
  justify-content: space-between;
  height: 80px;
  padding: 0 24px;
}

.actionButton {
  background-color: $primary-color;
  color: $text-color;
  border: none;
  border-radius: $border-radius;
  padding: $padding;
  font-size: 16px;
  cursor: pointer;
  text-align: center;
  text-decoration: none;
  display: inline-block;
  transition: background-color $transition-duration, transform $transition-duration;

  &:hover {
    background-color: $hover-color;
  }

  &:active {
    background-color: $active-color;
    transform: scale(0.98);
  }
}


.filter-status {
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 2vmax;
  color:  var(--as-gray);
 }
 
 .filter-indicators {
   height:30%;
   display: flex;
   justify-content: center;
   align-items: center;
   gap:1rem;
   min-height: 150px;
 }
 
 .filter-indicators div{
   display: flex;
   flex-direction: column;
   justify-content: center;
   align-items: center;
   border: 2px solid var(--color-offline);
   background:var(--disabled-gray);
   color: var(--as-gray);
   height:50%;
   width: 25%;
   border-radius: 20px;
 }
 
 .filter-indicators div.danger{
   border: 2px solid var(--color-danger);
   background:none;
   color: var(--color-danger);
   font-size: 2vmax;
 }
 
 .filter-indicators div.good{
   border: 2px solid var(--color-sucess);
   background:var(--color-sucess);
   color: var(--as-white);
   font-size: 3vmax;
   font-weight: bolder;
 }
 
 .filter-settings {
   display: flex;
   flex-direction: row;
   height:35%;
   gap:1rem;
   padding:2rem;
 }
 
 .filter-settings div{
   width:100%;
   display: flex;
   justify-content: center;
   align-items: center;
 }
 
 .filter.control-button {
   width: 25%;
 }
 
 .filter.control-button.toggle{
   font-size: 2vmax;
   width: 10%;
 }
 
 .filter.value {
   width: 50%;
   font-size: 5vmax;
   display: flex;
   flex-direction: column;
   justify-content: center;
   align-items: center;
   flex-wrap: nowrap;
   white-space:nowrap;
 }
 
 .filter-summary {
   display: flex;
   justify-content: center;
   align-items: center;
   text-align: center;
   color: var(--as-gray);
   font-size: 1.5vmax;
 }
 
 .filter-setpoint {
   height:20%;
   display: flex;
   justify-content: center;
   align-items: center;
   gap:1rem;
 }
 
 .filter-setpoint span{
   font-size: 1.5vmax;
 }

 @media only screen and (max-width: 768px){
  .filter-status {
    font-size: 3vmax;
   }
 
   
  .filter-indicators div{
    width: 100%;
  }

  .filter-settings {
    flex-direction: column;
    padding: 0;
  }

  .filter.control-button.toggle{
    width: 50%;
  }

  .filter-summary {
    font-size: 2vmax;
  }

  .filter-setpoint span{
    font-size: 2vmax;
  }

  }

 
 /**/
.back-chevron-icon {
  z-index: 9;
}

.menu-page-header {
  display: flex;
  position: relative;
  font-size: larger;
  justify-content: center;
  font-weight: 500;
  z-index: 99;
}

.filter-frequency-info {
  width: 100%;
}

.control-page-ph-cl {
  display: flex;
  flex-direction: column;
  align-items: flex-end;
}

.water-status-info {
  color: var(--as-gray);
  display: flex;
  gap:0.5rem;
}

.water-status-info div{
  display:flex;
  flex-direction: column;
  text-align: left;
}


.filter-frequency-info p {
  margin: 0.5rem 0;
}

.filtration-slider .MuiSlider-root {
  /* Padding determines thickness of the track */
  color: var(--font-red);
}

.links {
    display: flex;
    align-items: center;
    gap: 1ch;
}

.dealership-user-edit {
    display: flex;
    justify-content: space-between;
}

@import "variables";
@import "mixins";

.inputsContainer {
    display: flex;
    width:100%;
    flex-direction: column; 
    padding: 32px;
    gap:3rem;
    background: var(--as-white);
    border-radius: $border-radius;
    
    @include respond-to(desktop) {
        width:30%;
        transform: scale(1.1);
    }
}

.inputSection {
    .textfield {
        width:stretch;
        font-size: 1rem;
    }
}

.passwordShow{
    &:hover{
        cursor: pointer;
    }
}

.preferenceSelect {
    background: var(--as-white);
}
@import "variables";
@import "mixins";

.modifyButtonGroup{
    display: flex;
    flex-direction: column;
    align-items: center;
    z-index: 99;
}

.editButton{
    font-size: 1.25rem;
    color:var(--font-red);

    &:hover {
        cursor: pointer;
        user-select: none;
        color:var(--red-font-hover);
    }

    &:active{
        color:var(--red-font-hover);
    }

}

.addButton{
    width:25px;
    height:25px;
    stroke:var(--font-black);
    &:hover {
        cursor: pointer;
        user-select: none;
        stroke:var(--as-black);
    }
}

.section,
.yourSpasSection {
    color: var(--font-header);
    opacity:68%;
    display:grid;
    grid-template-columns: max-content auto;
    align-items: center;
    gap:1rem;
    font-weight: 500;
}

.yourSpasSection {
    grid-template-columns: 1fr min-content;
    gap:0;
    position: relative;
}

.divider {
    margin:1rem 0; 
}

.grid{
    display: flex;
    flex-direction: column;
    gap:1rem;
    max-height:calc(100vh - 160px);
    overflow-y: auto;
    
    @include respond-to(desktop) {
        display: grid;
        width: calc(100vw - 160px);
        grid-template-columns: repeat(2, 1fr);

        /* Scrollbar style */
        &::-webkit-scrollbar {
            width: 20px;
        }

        &::-webkit-scrollbar-track {
            background-color: transparent;
        }

        &::-webkit-scrollbar-thumb {
            background:  var(--scrollbar-thumb);
            border-radius: 25px;
            border: 6px solid transparent;
            background-clip: content-box;

            &:hover {
                background-color: var(--scrollbar-hover);
            }
        }
    }
}

.noSpas{
    display: flex;
    height: calc(100vh - 160px);
    justify-content: center;
    align-items: center;
    font-size: 1.5rem;
    color:var(--as-grey);
    @include respond-to(desktop) {
        width: calc(100vw - 160px);
    }
}

.center-nav {
  width:100%;
  font-size: 2rem;
  color:var(--font-black);
  padding:1rem;
}

.nav-btn-group {
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap:1rem;
}

.nav-btn-group button{
  background: var(--as-white);
  border:none;
  color:var(--as-gray);
  padding:1rem;
  font-size: 1.5rem;
  border-radius: 5px;
  box-shadow: 2px 2px 2px var(--box-shadow);
  display: grid;
  grid-template-columns: 24px 1fr;
  gap:0.5rem;
  position: relative;
  align-items: center;
  justify-content: center;
  overflow-x: hidden;
}
.nav-btn-group .button-icon{
  position: absolute;
  left: 1rem;
}

.nav-btn-group button:hover{
  cursor: pointer;
}

.nav-btn-group button:active{
  background: var(--disabled-gray);
  transform: scale(0.95);
}

.nav-btn-group .logout-button {
  background: var(--as-red);
  color: var(--pure-white);
  fill: var(--pure-white);
}

.nav-btn-group .logout-button:active {
  background: var(--hover-red);
}

.nav-btn-group .logout-button.full-row{
  grid-column: span 2;
}

@media (min-width: 1024px) {
  .center-nav {
    width:25%;
  }
}

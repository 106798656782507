@import "variables";
@import "mixins";

.homeScreen {
  margin: 0 1.5rem;
  min-height: calc(100vh);
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  @include respond-to(tablet) {
    margin: 0 3rem;
  }
}

.login-card {
  border-radius: 1rem;
  background-color: var(--as-white);
  border: solid 0.01rem var(--border-color);
  padding: 4rem;
  max-width: 30rem;
  min-width: 10rem;
  height: minmax(10rem, 50rem);
  color: var(--font-black);
  font-size: small;
}

.login-card-content {
  max-width: 20rem;
}
.login-header {
  font-size: x-large;
  display: flex;
  justify-content: flex-start;
  margin-bottom: 2rem;
}

.login-help-link {
  color: var(--font-red);
  text-decoration: none;
}

.tester {
}


@import "../../../App.css";

.header {
    display: flex;
    position: fixed;
    padding: 1rem;    
    width: stretch;
    top:0;
    left:0;
    height:75px;
    align-items: center;
    justify-content: space-between;
    z-index: 99;
    background: var(--background-main);
}


.right-group {
    display: flex;
    justify-content: center;
    align-items: center;
}

.right-group,
.user-avatar {
    z-index: 99;
}


.button-group {
    display: flex;
    gap:1rem;
    margin-left: 1rem;
}

.button-group div {
    text-decoration: none;
    color:var(--as-gray);
    transition: color 500ms;
    user-select: none;
}

.button-group div:hover{
    color:var(--font-gray);
    cursor: pointer;
}

.button-group.hide-in-mobile{
    display:none;
}

.show-in-mobile{
    display:inherit;
}

.logo {
    display: flex;
    height: 100%;
    width: 100%;
    justify-content: center;
    align-items: center;
}

.logo p {
    font-size: 1.25rem;
}

.user-avatar {
    padding-right: 1rem;
}

.user-avatar button {
    width:45px;
    height:45px;
    border-radius: 100%;
    border: none;
    color:var(--pure-white);
    background: var(--as-red);
    font-size: 1.25rem;
}

.drawer .MuiPaper-root  {
    padding:1rem;
    scrollbar-gutter: stable both-edges;
    width: fit-content;
}

.MuiListItem-root {
    border-radius: 10px;
    fill: var(--pure-white);
}

.right-buttons {
    display: flex;
    margin-right: 1rem;
    gap:1rem;
    z-index: 99;
    white-space: nowrap;
}


.logout {
    color: var(--font-red);
    user-select: none;
}

.logout:hover {
    color: var(--red-font-hover);
    cursor: pointer;
}

.logout:active {
    color: var(--red-font-hover);
    transform: scale(0.95);
}

@media (min-width:1200px) { 
    .button-group.hide-in-mobile{
        display:inherit;
    }
    
    .show-in-mobile{
        display:none;
    }

    .logo {
        transform: scale(1);
    }

    .drawer .MuiPaper-root  {
        max-width: 500px;
        min-width: 400px;
    }
}
.user {
    display: flex;
    flex-direction: column;
    height:100%;
}

.details {
    background: none;
    height:100%;
    display: flex;
    flex-direction: column;
    gap:1rem;
}

.card-title{
    font-size: 1.25rem;
    color:var(--font-red);
    display: flex;
    justify-content: space-between;
    gap:10px;
    white-space:wrap;
}

.details hr{
    border:1px solid var(--font-red);
    width: stretch;
}

.role {
    text-transform: capitalize;
}
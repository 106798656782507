/* Main colors */
$primary-color: #3498db;
$hover-color: #2980b9;
$active-color: #1abc9c;
$text-color: #ffffff;
$as-gray: rgb(121, 125, 130);
$border-radius: 5px;
$padding: 10px 20px;
$transition-duration: 0.3s;

/* Responsive */
$breakpoint-mobile: 480px;
$breakpoint-tablet: 768px;
$breakpoint-desktop: 1024px;
  .container {
    display: flex;
    flex-direction: column;
    background: var(--as-white);
    width:stretch;
    margin: 32px;
    margin-top: 128px;
    border-radius: 10px;
}


  .list {
    display: flex;
    background: var(--as-white);
    height:82vh;
    border-radius: 10px;
    position: relative;
  }

  
  .list.reports {
    height:75vh
  }

  .filters {
      display:none;
      scrollbar-gutter: stable both-edges;
      min-width: 20rem;
      z-index: 99;
    }

    .search, .firmware-updates {
      display: flex;
      flex-direction: column;
      gap:0.5rem;
      margin-top: 1rem;
    }

    .search .checkbox-filters{
      display: flex;
      justify-content: space-between;
      font-size: 1.1rem;
      padding: 0 0.5rem;
      }

      .search .checkbox-filters input{
        height:15px;
        width:15px;
        margin:1rem 1rem 0 0;
    }

    .filter-btn-group {
      display: flex;
      flex-direction: column;
      padding-top:0.5rem;
      gap:0.5rem;
      }

      .filter-btn-group button{
        font-size: 1.25rem;
        color:var(--pure-white);
        background: var(--as-red);
        border:none;
        border-radius: 10px;
        padding:0.5rem;
        user-select: none;
        white-space:nowrap;
        z-index: 100;
        overflow: hidden;
      }

      .filter-btn-group button:hover{
        background: var(--hover-red);
        cursor: pointer;
      }

      .filter-btn-group button:active{
        transform: scale(0.95);
      }

      .filter-btn-group button:disabled{
        background: var(--disabled-gray);
        color: var(--disabled-font);
        transform: scale(0.95);
      }

      .filter-btn-group.spa-actions {
        flex-direction: row;
        position: absolute;
        bottom:0;
        left:0;
        margin-bottom: 1rem;
      }

  .list p{
    position: absolute;
    bottom:0;
    right:32px;
    color:var(--title-gray)
  }

  .list p b{
    color:var(--font-black)
  }
.filters hr {
    border: 1px solid var(--border-color);
    width:stretch;
}

.table-section {
  position: relative;
  width: stretch;
  overflow-x: hidden;
}

.firmware-versions {
    display: flex;
    justify-content: space-between;
    gap:1rem;
}

.search span {
    color:var(--as-gray)
}

.date-input {
    width: stretch;
    display:flex;
    flex-direction: column;
    margin-bottom: 1rem;
}

.date-input input[type="date"],
.date-input .select-style{
    height:3rem;
    border-radius: 15px;
    padding:1rem;
    background: var(--pop-over);
    color: var(--font-black)
}


.date-input input[type="date"],
select.select-style,
select.date-range{
  border:1px solid var(--as-gray);
}

.date-input p{
    color: var(--font-header);
    position:initial;
    margin:0;
}

.table {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    padding:0.25rem;
    overflow: auto;
    display: flex;
    position: relative;
    width:stretch;
    height:88%;
    scrollbar-gutter: stable both-edges;
  }

  /* Scrollbar settings */
  .table::-webkit-scrollbar,
  .filters::-webkit-scrollbar,
  .MuiPaper-root::-webkit-scrollbar{
      width: 20px;
    }
    
  .table::-webkit-scrollbar-track,
  .filters::-webkit-scrollbar-track,
  .MuiPaper-root::-webkit-scrollbar-track{
    background-color: transparent;
  }
  
  .table::-webkit-scrollbar-thumb,
  .filters::-webkit-scrollbar-thumb,
  .MuiPaper-root::-webkit-scrollbar-thumb {
    background:  var(--scrollbar-thumb);
    border-radius: 25px;
    border: 6px solid transparent;
    background-clip: content-box;
  }
    
  .table::-webkit-scrollbar-thumb:hover,
  .filters::-webkit-scrollbar-thumb:hover,
  .MuiPaper-root::-webkit-scrollbar-thumb:hover {
    background-color: var(--scrollbar-hover);
  }

  .MuiTable-root {
    user-select: none;
  }

  .table a {
    color:var(--font-red)
  }

  .table a:hover {
    color:var(--red-font-hover);
  }

  .table .clickable-row:hover{
    cursor: pointer;
  }

  .last-online {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }

  .last-online small{
    color:var(--title-gray)
  }

  .table-footer{
    display: flex;
    justify-content: flex-end;
    position: absolute;
    background: var(--as-white);
    border-radius: 10px;
    width:100%;
    right:0;
    bottom:0;
    padding: 0.5rem 1rem;
    gap:2rem; 
  }

  .pagination{
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-end;
  }

  .pagination .total-count{
    color: var(--as-gray);
  }

  .list-settings {
    height:32px;
    width: 32px;
    margin-top: 1rem;
  }

  .popover-checkboxes {
    display: flex;
    flex-direction: column;
    padding: 1rem;
    gap:10px;
    background:var(--pop-over);
    color:var(--font-black);
  }

  .empty-table {
    display: flex;
    height: 100%;
    width:stretch;
    justify-content: center;
    align-items: center;
    font-size: 1.5rem;
    color:var(--as-grey);
  }

  .clickable-row:hover{
    cursor: pointer;
  }
  
@media (min-width: 768px) {

  .filters {
    border-right: 1px solid var(--border-color);
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    padding: 0 2rem 0 1rem;
    transform: scale(0.95);
    gap:1rem;
    overflow-x: hidden;
    width:20%;
  }
}
@import "variables";
@import "mixins";

.settingsContainer{
    display: flex;
    flex-direction: column;
    position: absolute;
    top:10vh;
    width:90%;
    gap:1rem;
    margin: {
        top:1rem;
    };

    @include respond-to(desktop) {
        padding: 0 32px;
        width:30%;
        transform: scale(1.1);
    }
}

.header{
    font-weight: 500;
    color: var(--font-header);
    opacity: 68%;
    margin: {
        bottom: 0;
    }
}

.buttonGroup{
    display: flex;
    flex-direction: column;
    background: var(--as-white);
    padding: 0.5rem 1rem;
    border-radius: 5px;
    margin: {
        bottom: 1rem;
    };

    .button:last-child{
        border-bottom: none;
    }
}

.clickableDiv{
    width: 100%;
    justify-content: space-between;
    &:hover{
        cursor: pointer;
        user-select: none;
    }
}

.button {
    color: var(--font-black);
    text-transform: none;
    border-radius: 0%;
    background: var(--as-white);
    border: none;
    border-bottom: 1px solid var(--border-color);
    text-align: left;
    font-size: 1rem;

    &:hover {
        cursor: pointer;
    }

    &:active {
        background: var(--as-white);
        user-select: none;
    }
}

.buttonContent,
.unitButton{
    display: grid;
    grid-template-columns: max-content max-content;
    justify-content: space-between;
    align-items: center;
}

.unitButton {
    gap:0.5rem;
    color:var(--font-header);
}

.link {
    text-decoration: none;
    color:var(--font-black);
}
.modal-btn-group{
    display: flex;
    justify-content: space-between;
}

    .modal-btn-group{
        display: flex;
        justify-content: space-between;
    }

    .modal-btn-group button {
        font-size: 1rem;
        border:none;
        border-radius: 10px;
        padding:0.5rem;
        user-select: none;
        white-space:nowrap;
        z-index: 100;
    }

    .modal-btn-group .as-button{
      color:var(--as-white);
      background: var(--font-red);

    }

    .modal-btn-group .as-text{
        font-size: 1rem;
        color:var(--font-red);
        background: none;
    }

    .modal-btn-group .as-button:hover{
      background: var(--red-font-hover);
      cursor: pointer;
    }

    .modal-btn-group .as-text:hover{
        color: var(--red-font-hover);
        cursor: pointer;
      }

    .modal-btn-group .as-button:active,
    .modal-btn-group .as-text:active{
      transform: scale(0.95);
    }

    .modal-btn-group .as-button:disabled{
      background: var(--disabled-gray);
      transform: scale(0.95);
    }

    .modal-btn-group .as-text:disabled{
        color: var(--disabled-gray);
        transform: scale(0.95);
      }
      
.spa-select {
  display: flex;
  flex-direction: column;
  gap: 1rem;
  overflow: auto;
  max-height: 150px;
  scrollbar-gutter: stable both-edges;
  padding: 0 1rem;
}

.update-results {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 1rem;
}

.spa-select div:last-child{
  margin-bottom: 1rem;
}

.partial-guid{
  width: 9ch;
  text-overflow:ellipsis;
  overflow:hidden;
  white-space:nowrap;
}

.name-guid {
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
}

.spas-title{
  margin-bottom: 0.5rem;
}

.request {
  transform: scale(1.25);
}
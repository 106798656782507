.dialog-container {
    position: fixed;
    visibility: visible;
    background: var(--pop-over);
    left: 0; 
    right: 0; 
    margin-inline: auto; 
    width: 50vmax;
    font-size: 1vmax;
    border: 1px solid var(--as-red);
    border-top: none;
    border-bottom-left-radius: 20px 20px;
    border-bottom-right-radius: 20px 20px;
    box-shadow: var(--box-shadow) 0px 13px 27px -5px, var(--box-shadow) 0px 8px 16px -8px;
    z-index: 999;
}

.dialog-container.show {
    visibility: visible;
    opacity: 1;
    transition: visibility 0s, opacity 0.5s linear;
}

.dialog-container.hidden {
    visibility: hidden;
    opacity: 0;
    transition: visibility 0.5s, opacity 0.5s linear;
}

.impersonation-select {
    font-size: 1vmax;
    padding: 0.5rem;
}

.dialog-content,
.impersonation-action {
    position: sticky;
    display: flex;
    gap:1rem;
    align-items: center;
}


.dialog-content {
    width: stretch;
    display: flex;
    padding: 0 1rem;
    justify-content: space-between;
    align-items: center;
}



.controls-page-container.spaboy .levels{
  display: flex;
  height:40%;
  justify-content: center;
  align-items: center;
}

.levels-container{
  display: grid;
  grid-template-columns: repeat(6,1fr);
  grid-template-rows: repeat(3,1fr);
  border: 2px solid var(--title-gray);
  height:80%;
  width: 50%;
  border-radius: 20px;
  overflow: hidden;
  align-items: center;
  justify-content: center;
  text-align: center;
  font-size: 2.5vmin;
  padding: 1rem;
  user-select: none;
}

.tooltip-title {
  font-size: 1.5vmax;
}

.controls-page-container.spaboy .icon-indicators{
  height:10%;
  display: flex;
  justify-content: center;
  align-items: center;
}

.controls-page-container.spaboy .controls{
  height:20%;
  display: flex;
  justify-content: center;
  align-items: center;
  gap:1rem;
}

.controls-page-container.spaboy .settings{
  height:30%;
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
}

.spaboy-manual {
  display: flex;
  justify-content: center;
  align-items: center;
  gap:1rem;
  width: 100%;
  padding: 1rem;
}

.spaboy-hours {
  display: flex;
  width: stretch;
  justify-content: center;
  align-items: center;
  gap:2rem;
}

.value.spaboy{
  width: 20%;
  font-size: 5vmax;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  flex-wrap: nowrap;
  white-space:nowrap;
}

.value.spaboy .units{
  font-size: 2vmax;
}

.control-button.toggle.spaboy-controls {
  width:25%
}

.control-button.toggle.spaboy {
  width:25%;
  display: flex;
  flex-direction: column;
}

.control-button.toggle.spaboy:disabled path {
  stroke: var(--icon-disabled)
}

.control-button.toggle.spaboy.active path {
  stroke: var(--pure-white)
}

.control-button.arrow.spaboy {
  width:20%
}

@media only screen and (max-width: 768px){
  .control-button.toggle.spaboy-controls {
    width:100%
  }
  
  .control-button.toggle.spaboy {
    width:100%;
  }
  
  .control-button.arrow.spaboy {
    width:100%
  }

  .levels-container{
    height:100%;
    width: 100%;
    font-size:5vmin;
  }

  .spaboy-manual {
    padding: 0;
  }

}



/**/
.water-status {
  /* justify-content: space-around; */
  display: grid;
  grid-template-columns: 2fr 1fr;
  gap: 0.5rem;
}

.status-bar-line {
  display: flex;
  color: var(--font-black);
  width: 100%;
}

.tabbed-switch-container {
  width: -webkit-fill-available;
  display: flex;
  justify-content: space-evenly;

  border-radius: 999rem;
  background-color: white;
  border: solid  var(--color-gray) 0.01rem;
  margin-top: 0.5rem;
}

.tab-item {
  padding: 0.5rem;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 3rem;
  width: -webkit-fill-available;
  height: 3rem;
  transition: background-color 0.2s;
}

.tab-item.active {
  background-color:  var(--as-red);
  color:  var(--font-white);
}

.tab-item.active svg path {
  stroke:  var(--font-white);
}

.person-icon {
  display: flex;
}

.person-icon span {
  margin-left: 5px;
  font-size: 1.2rem;
  font-weight: bold;
}

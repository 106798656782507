@import "variables";

@mixin respond-to($device) {
  @if $device == mobile {
    @media (max-width: #{$breakpoint-mobile}) {
      @content;
    }
  } @else if $device == tablet {
    @media (min-width: #{$breakpoint-mobile + 1}) and (max-width: #{$breakpoint-tablet}) {
      @content;
    }
  } @else if $device == desktop {
    @media (min-width: #{$breakpoint-desktop}) {
      @content;
    }
  }
}

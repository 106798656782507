.reports-header {
    width:stretch;
    padding: 1rem 0;
    margin: 0 1rem;
    border-bottom: 1px solid var(--border-color);
    font-size: 1.1rem;
}

.reports-header div{
    display: flex;
    gap:0.5rem;
    align-items: center;
}

.checkbox-group{
    display: flex;
    flex-direction: column;
    gap: 1rem;
    color: var(--font-black);
}

.checkbox-group label{
    display: flex;
    align-items: center;
    gap:0.25rem;
    font-size: 1.1rem;
}

.MuiAccordionSummary-root{
    background-color: var(--as-white) !important;
    color: var(--as-gray) !important;
    font-size: 1.25rem;
}

.dates{
    display: flex;
    gap: 0.25rem;
    padding: 1rem 0;
    color: var(--as-gray);
}

.dates input {
    border: 1px solid var(--title-gray);
    padding: 0.25rem;
    border-radius: 5px;
}

.reports-sticky{
    position: sticky;
    bottom:0;
    z-index: 99;
    background: var(--as-white);
}

.date-range{
    width:max-content;
    border:1px solid var(--border-color);
    border-radius: 10px;
    padding: 0.25rem;
    margin-bottom: 0.25rem;
    background: var(--pop-over);
    color: var(--font-black)
}

.since-date{
    display: flex;
    align-items: center;
    justify-content: space-between;
}

.rechart-tooltip{
    background-color: var(--as-white);
    padding:1rem;
    border: 1px solid var(--border-color);
    border-radius: 5px;
    box-shadow: var(--box-shadow) 0px 4px 12px;
}
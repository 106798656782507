@import "../../App.css";

.controls-page-container {
  width: 80%;
  height: calc(100vh - 175px);
  padding: 0 1rem;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  flex-grow: 0;
  gap:1rem;
  min-width: 250px;
}

.control-button {
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
  background-color: var(--as-red);
  color:var(--pure-white);
  border: 2px solid var(--title-gray);
  border-radius: 15px;
  padding: 1rem;
  z-index: 99;
}

.control-button.toggle {
  font-size: larger;
  font-weight: bolder;
  background-color: var(--as-white);
  color:var(--font-black);
}

.control-button.toggle.active {
  border: 2px solid var(--font-black);
  background-color: var(--as-red);
  color:var(--pure-white);
}

.control-button:active {
  border: 2px solid var(--font-black);
  background-color: var(--hover-red);
}

.control-button:disabled,
.control-button.active:disabled  {
  border: 2px solid var(--icon-disabled);
  color:var(--icon-disabled);
  background-color: var(--disabled-gray);
}

.control-button.active:disabled {
  background-color: var(--border-color);
}

.top-section {
  height: 60%;
  display: flex;
  width: 100%;
  flex-grow: 0;
  min-height: 250px;
}

.bottom-section {
  height: 40%;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-grow: 0;
  min-height: 100px;
}

.temperature{
  display: flex;
  flex-direction: column;
  width: 25%;
  align-items: center;
  justify-content: space-between;
  text-align: center;
  padding: 1rem;
  z-index: 99;
  min-height: 50px;
}

.spa-section{
  width: 50%;
  height: 100%;
  display: flex;
  flex-direction: column;
  min-height: 150px;
}

.nav{
  width: 25%;
  display: flex;
  flex-direction: column;
  gap:1rem;
  align-items: center;
  justify-content: flex-start;
  padding: 1rem;
  min-height: 50px;
}

.temperature .arrow-button {
  width: 100%;
  background: none;
  border: none;
  height: 100%;
  color: var(--font-black);
}

.arrow-button:disabled {
  color: var(--icon-disabled);
}

.values{
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap:0;
  line-height:normal;
}

.units  {
  font-size: 1rem;
  font-weight: lighter;
}

.values .current{
  font-weight: bolder;
  font-size: 10vmin;
}

.values .current span{
  font-weight: lighter;
  font-size: 5vmin;
}

.values .set-point{
  color: var(--as-gray);
  font-size: 5vmin;
}

.values .set-point span{
  font-size: 3vmin;
}

.spa-section .spa-image {
  height:25vmax;
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  overflow: hidden;
}

.spa-section .spa-status {
  height:15%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  letter-spacing: 0.25rem;
  font-size: 5vmin;
}

.spa-status.offline{
  color:var(--disabled-text)
}

.spa-status.heating{
  color:var(--font-red)
}

.spa-status.idle{
  color:var(--font-black)
}

.spa-image .overlay,
.spa-image .image {
  height: 100%;
  width: 100%;
  position: absolute;
}

.spa-image .image {
  background-image: url('../../assets/images/Spa/all-pumps-off.png');
  background-position: center;
  background-size: contain;
  background-repeat: no-repeat;
}

.spa-image .image.offline,
.ampere.offline{
  filter: grayscale(1);
}

.spa-image .image.p1{
  background-image: url('../../assets/images/Spa/1-pump-on.png');
}

.spa-image .image.p2{
  background-image: url('../../assets/images/Spa/pumps-2-on.png');
}

.spa-image .image.p3{
  background-image: url('../../assets/images/Spa/pumps-3-on.png');
}

.spa-image .image.p1.p2{
  background-image: url('../../assets/images/Spa/1-2-pump-on.png');
}

.spa-image .image.p1.p3{
  background-image: url('../../assets/images/Spa/1-3-pump-on.png');
}

.spa-image .image.p2.p3{
  background-image: url('../../assets/images/Spa/pumps-2-3-on.png');
}


.spa-image .image.p1.p2.p3{
  background-image: url('../../assets/images/Spa/all-pumps-on.png');
}

.spa-image .image.p1,
.spa-image .image.p2,
.spa-image .image.p3,
.spa-image .image.p1.p2,
.spa-image .image.p1.p3,
.spa-image .image.p2.p3, 
.spa-image .image.p1.p2.p3 {
  background-position: center;
  background-size: contain;
  background-repeat: no-repeat;
  animation-name: pump-animation;
  animation-duration: 1s;
  animation-iteration-count: infinite;
  animation-direction: alternate;
  animation-timing-function: ease-in-out;
}

/* The animation code */
@keyframes pump-animation {
  from {opacity: 85%;}
  to {opacity: 50%;}
}

.spa-image .overlay {
  display: grid;
  position: absolute;
  width: 100%;
  max-width: 50vmin;
  max-height: 50vmin;
  margin: 0 auto;
  border-radius: 20px;
  z-index: 99;
  overflow: hidden;
  grid-template-columns: 1fr 1fr;
  grid-template-rows: 1fr 1fr;
}

.overlay #Q1,
.overlay #Q2,
.overlay #Q3,
.overlay #Q4 {
  display: flex;
  padding:1rem;
  background: none;
  border: none;
}

.overlay #Q1{
  justify-content: flex-end;
  align-items: flex-end;
}

.overlay #Q2{
  justify-content: flex-start;
  align-items: flex-end;
}

.overlay #Q3{
  justify-content: flex-end;
  align-items: flex-start;
}

.overlay #Q4{
  justify-content: flex-start;
  align-items: flex-start;
}

.nav-btn {
  height:15%;
  width: 100%;
  display: flex;
  align-items: center;
  padding: 0.5rem 1rem;
  gap: 10px;
  border-radius: 10px;
  border: none;
  background-color: var(--arctic-red);
  color: var(--pure-white);
  overflow: hidden;
  text-transform: uppercase;
  letter-spacing: 2px;
  justify-content: space-between;
}

.nav-btn:hover {
  cursor: pointer;
}

#ozone-icon {
  background-image: url('../../assets/images/Ozone/ozone_icon.png');
  background-position: center;
  background-size: contain;
  background-repeat: no-repeat;
}

#onzen-icon {
  background-image: url('../../assets/images/Onzen/onzen_icon.png');
  background-position: center;
  background-size: contain;
  background-repeat: no-repeat;
}

.nav-btn#spa-boy{
  background: none;
  justify-content: center;
}

.nav-btn#spa-boy{
  background-color: rgb(30,171,226);
  padding-left: 0.5rem;
}

.nav-btn img#sb-logo{
  width:80%;
  filter: brightness(0) invert(1);
}

.nav-btn#filters path,
.nav-btn#sanitize path{
  stroke: var(--pure-white)
}

.sanitation-icon {
  height: 100%;
  width: 30%;
  display: flex;
  align-items: center;
  justify-content: center;
}

.sanitation-icon#filter-icon svg{
  width: 80px;
}

.nav-btn p {
  width: 70%;
  font-size: 2vmin;
  text-align: left;
}

.action-buttons {
  display: flex;
  height: 30%;
  width: 75vw;
  justify-content: space-between;
  gap:1rem;
}

.action-button{
  border: none;
  width:100%;
  height: 100%;
}

.indicator{
  width:25%;
  height: 25%;
}

/* ELECTRODE BATTERY */
.action-button.electrode#E1{
  background: url('../../assets/images/ElectrodeWear/electrode_wear_1.png');
  background-position: center;
  background-size:contain;
  background-repeat: no-repeat;
}
.action-button.electrode#E2{
  background: url('../../assets/images/ElectrodeWear/electrode_wear_2.png');
  background-position: center;
  background-size:contain;
  background-repeat: no-repeat;
}
.action-button.electrode#E3{
  background: url('../../assets/images/ElectrodeWear/electrode_wear_3.png');
  background-position: center;
  background-size:contain;
  background-repeat: no-repeat;
}
.action-button.electrode#E4{
  background: url('../../assets/images/ElectrodeWear/electrode_wear_4.png');
  background-position: center;
  background-size:contain;
  background-repeat: no-repeat;
}
.action-button.electrode#E5{
  background: url('../../assets/images/ElectrodeWear/electrode_wear_5.png');
  background-position: center;
  background-size:contain;
  background-repeat: no-repeat;
}

/* PUMP1 */
/*Default*/
.action-button.default#P1,
.indicator.default#P1,
.action-button.offline#P1,
.indicator.offline#P1{
  background: url('../../assets/images/Pump1/default.png');
  background-position: center;
  background-size:contain;
  background-repeat: no-repeat;
}
.action-button.default#P1:active,
.action-button.default#P1:hover{
  background: url('../../assets/images/Pump1/default_highlight.png');
  background-position: center;
  background-size:contain;
  background-repeat: no-repeat;
  cursor: pointer;
}

/*Low*/
.action-button.low#P1,
.indicator.low#P1{
  background: url('../../assets/images/Pump1/selected_low.png');
  background-position: center;
  background-size:contain;
  background-repeat: no-repeat;
}
.action-button.low#P1:hover,
.action-button.low#P1:active{
  background: url('../../assets/images/Pump1/selected_highlight_low.png');
  background-position: center;
  background-size:contain;
  background-repeat: no-repeat;
  cursor: pointer;
}

/*High*/
.action-button.high#P1,
.indicator.high#P1{
  background: url('../../assets/images/Pump1/selected.png');
  background-position: center;
  background-size:contain;
  background-repeat: no-repeat;
}
.action-button.high#P1:active,
.action-button.high#P1:hover{
  background: url('../../assets/images/Pump1/selected_highlight.png');
  background-position: center;
  background-size:contain;
  background-repeat: no-repeat;
  cursor: pointer;
}

/* PUMP2 */
/*Default*/
.action-button.default#P2,
.indicator.default#P2,
.action-button.offline#P2,
.indicator.offline#P2{
  background: url('../../assets/images/Pump2/default.png');
  background-position: center;
  background-size:contain;
  background-repeat: no-repeat;
}
.action-button.default#P2:active,
.action-button.default#P2:hover{
  background: url('../../assets/images/Pump2/default_highlight.png');
  background-position: center;
  background-size:contain;
  background-repeat: no-repeat;
  cursor: pointer;
}
/*Low*/
.action-button.low#P2,
.indicator.low#P2{
  background: url('../../assets/images/Pump2/selected_low.png');
  background-position: center;
  background-size:contain;
  background-repeat: no-repeat;
}
.action-button.low#P2:active,
.action-button.low#P2:hover{
  background: url('../../assets/images/Pump2/selected_highlight_low.png');
  background-position: center;
  background-size:contain;
  background-repeat: no-repeat;
  cursor: pointer;
}

/*High*/
.action-button.high#P2,
.indicator.high#P2{
  background: url('../../assets/images/Pump2/selected.png');
  background-position: center;
  background-size:contain;
  background-repeat: no-repeat;
}
.action-button.high#P2:active,
.action-button.high#P2:hover{
  background: url('../../assets/images/Pump2/selected_highlight.png');
  background-position: center;
  background-size:contain;
  background-repeat: no-repeat;
  cursor: pointer;
}

/* PUMP3 */
/*Default*/
.action-button.default#P3,
.indicator.default#P3,
.action-button.offline#P3,
.indicator.offline#P3{
  background: url('../../assets/images/Pump3/default.png');
  background-position: center;
  background-size:contain;
  background-repeat: no-repeat;
}
.action-button.default#P3:active,
.action-button.default#P3:hover{
  background: url('../../assets/images/Pump3/default_highlight.png');
  background-position: center;
  background-size:contain;
  background-repeat: no-repeat;
  cursor: pointer;
}
/*Low*/
.action-button.low#P3,
.indicator.low#P3{
  background: url('../../assets/images/Pump3/selected_low.png');
  background-position: center;
  background-size:contain;
  background-repeat: no-repeat;
}
.action-button.low#P3:active,
.action-button.low#P3:hover{
  background: url('../../assets/images/Pump3/selected_highlight_low.png');
  background-position: center;
  background-size:contain;
  background-repeat: no-repeat;
  cursor: pointer;
}
/*High*/
.action-button.high#P3,
.indicator.high#P3{
  background: url('../../assets/images/Pump3/selected.png');
  background-position: center;
  background-size:contain;
  background-repeat: no-repeat;
}
.action-button.high#P3:active,
.action-button.high#P3:hover{
  background: url('../../assets/images/Pump3/selected_highlight.png');
  background-position: center;
  background-size:contain;
  background-repeat: no-repeat;
  cursor: pointer;
}

/* PUMP4 */
/*Default*/
.action-button.default#P4,
.indicator.default#P4,
.action-button.offline#P4,
.indicator.offline#P4{
  background: url('../../assets/images/Pump4/default.png');
  background-position: center;
  background-size:contain;
  background-repeat: no-repeat;
}
.action-button.default#P4:active,
.action-button.default#P4:hover{
  background: url('../../assets/images/Pump4/default_highlight.png');
  background-position: center;
  background-size:contain;
  background-repeat: no-repeat;
  cursor: pointer;
}
/*Low*/
.action-button.low#P4,
.indicator.low#P4{
  background: url('../../assets/images/Pump4/selected_low.png');
  background-position: center;
  background-size:contain;
  background-repeat: no-repeat;
}
.action-button.low#P4:active,
.action-button.low#P4:hover{
  background: url('../../assets/images/Pump4/selected_highlight_low.png');
  background-position: center;
  background-size:contain;
  background-repeat: no-repeat;
  cursor: pointer;
}
/*High*/
.action-button.high#P4,
.indicator.high#P4{
  background: url('../../assets/images/Pump4/selected.png');
  background-position: center;
  background-size:contain;
  background-repeat: no-repeat;
}
.action-button.high#P4:active,
.action-button.high#P4:hover{
  background: url('../../assets/images/Pump4/selected_highlight.png');
  background-position: center;
  background-size:contain;
  background-repeat: no-repeat;
  cursor: pointer;
}

/* PUMP5 */
/*Default*/
.action-button.default#P5,
.action-button.offline#P5{
  background: url('../../assets/images/Pump5/default.png');
  background-position: center;
  background-size:contain;
  background-repeat: no-repeat;
}
.action-button.default#P5:active,
.action-button.default#P5:hover{
  background: url('../../assets/images/Pump5/default_highlight.png');
  background-position: center;
  background-size:contain;
  background-repeat: no-repeat;
  cursor: pointer;
}
/*Low*/
.action-button.low#P5{
  background: url('../../assets/images/Pump5/selected_low.png');
  background-position: center;
  background-size:contain;
  background-repeat: no-repeat;
}
.action-button.low#P5:active,
.action-button.low#P5:hover{
  background: url('../../assets/images/Pump5/selected_highlight_low.png');
  background-position: center;
  background-size:contain;
  background-repeat: no-repeat;
  cursor: pointer;
}
/*High*/
.action-button.high#P5{
  background: url('../../assets/images/Pump5/selected.png');
  background-position: center;
  background-size:contain;
  background-repeat: no-repeat;
}
.action-button.high#P5:active,
.action-button.high#P5:hover{
  background: url('../../assets/images/Pump5/selected_highlight.png');
  background-position: center;
  background-size:contain;
  background-repeat: no-repeat;
  cursor: pointer;
}

/* LIGHTS */
.action-button.default#Lights,
.indicator.default#Lights,
.action-button.offline#Lights,
.indicator.offline#Lights{
  background: url('../../assets/images/Lights/default.png');
  background-position: center;
  background-size:contain;
  background-repeat: no-repeat;
}

.action-button.default#Lights:active,
.action-button.default#Lights:hover{
  background: url('../../assets/images/Lights/default_highlight.png');
  background-position: center;
  background-size:contain;
  background-repeat: no-repeat;
  cursor: pointer;
}

.action-button.selected#Lights,
.indicator.selected#Lights{
  background: url('../../assets/images/Lights/selected.png');
  background-position: center;
  background-size:contain;
  background-repeat: no-repeat;
}

.action-button.selected#Lights:active,
.action-button.selected#Lights:hover{
  background: url('../../assets/images/Lights/selected_highlight.png');
  background-position: center;
  background-size:contain;
  background-repeat: no-repeat;
  cursor: pointer;
}

/* FOGGER */
.action-button.default#Fogger,
.action-button.offline#Fogger{
  background: url('../../assets/images/Fogger/default.png');
  background-position: center;
  background-size:contain;
  background-repeat: no-repeat;
}

.action-button.default#Fogger:active,
.action-button.default#Fogger:hover{
  background: url('../../assets/images/Fogger/default_highlight.png');
  background-position: center;
  background-size:contain;
  background-repeat: no-repeat;
  cursor: pointer;
}

.action-button.selected#Fogger{
  background: url('../../assets/images/Fogger/selected.png');
  background-position: center;
  background-size:contain;
  background-repeat: no-repeat;
}

.action-button.selected#Fogger:active,
.action-button.selected#Fogger:hover{
  background: url('../../assets/images/Fogger/selected_highlight.png');
  background-position: center;
  background-size:contain;
  background-repeat: no-repeat;
  cursor: pointer;
}

/* SDS */
.action-button.default#SDS,
.indicator.default#SDS,
.action-button.offline#SDS,
.indicator.offline#SDS{
  background: url('../../assets/images/SDS/default.png');
  background-position: center;
  background-size:contain;
  background-repeat: no-repeat;
}

.action-button.default#SDS:active,
.action-button.default#SDS:hover{
  background: url('../../assets/images/SDS/default_highlight.png');
  background-position: center;
  background-size:contain;
  background-repeat: no-repeat;
  cursor: pointer;
}

.action-button.selected#SDS,
.indicator.selected#SDS{
  background: url('../../assets/images/SDS/selected.png');
  background-position: center;
  background-size:contain;
  background-repeat: no-repeat;
}

.action-button.selected#SDS:active,
.action-button.selected#SDS:hover{
  background: url('../../assets/images/SDS/selected_highlight.png');
  background-position: center;
  background-size:contain;
  background-repeat: no-repeat;
  cursor: pointer;
}

/* YESS */
.action-button.default#YESS,
.action-button.offline#YESS{
  background: url('../../assets/images/YESS/default.png');
  background-position: center;
  background-size:contain;
  background-repeat: no-repeat;
}

.action-button.default#YESS:active,
.action-button.default#YESS:hover{
  background: url('../../assets/images/YESS/default_highlight.png');
  background-position: center;
  background-size:contain;
  background-repeat: no-repeat;
  cursor: pointer;
}

.action-button.selected#YESS{
  background: url('../../assets/images/YESS/selected.png');
  background-position: center;
  background-size:contain;
  background-repeat: no-repeat;
}

.action-button.selected#YESS:active,
.action-button.selected#YESS:hover{
  background: url('../../assets/images/YESS/selected_highlight.png');
  background-position: center;
  background-size:contain;
  background-repeat: no-repeat;
  cursor: pointer;
}

.arrow-button .MuiSvgIcon-root {
  font-size: 8vmin;
}

.ampere{
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}

.ampere.mobile{
  display: none;
}

/**/
.primary-window {
  /* padding creates scrollbar when using 100vh */
  padding: 2rem 2rem 0 2rem;
  display: flex;
  margin-top: 40px;
  min-height: calc(100vh - 75px);
  flex-direction: column;
  align-content: space-between;
}

.statuses {
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 4px;
}

.spa-nickname {
  display: flex;
  font-size: x-large;
  color: var(--font-black);
  width: 15rem;
  align-items: flex-start;
  z-index: 9991;
  cursor: pointer;
  gap: 0.5rem;
}

.status-indicator {
  margin-top: 2px;
  display: inline-block;
  width: 0.5rem;
  height: 0.5rem;
  border-radius: 50%;
  margin-right: 5px;
  animation: pulse 2s infinite;
}

.status-indicator.online {
  background-color: var(--color-sucess);
  box-shadow: 0 0 0.1rem var(--color-sucess);
}

.status-indicator.offline {
  background-color: var(--color-offline);
  animation: none;
}

.status-indicator.not-found {
  background-color: var(--color-warning);
  box-shadow: 0 0 5px var(--color-warning);
}

.dropdown-menu {
  position: absolute;

  background: var(--as-white);
  border: 1px solid var(--border-color);
  padding: 8px;
  box-shadow: 0px 1px 3px var(--box-shadow);
  z-index: 10;
  transform: translateY(0.25rem);
  cursor: pointer;
}

.dropdown-menu ul {
  list-style: none;
  padding: 0;
  margin: 0;
}

.dropdown-menu li {
  padding: 0.5rem;
  cursor: pointer;
}

.dropdown-items {
  display: flex;
  width: 100%;
  gap: 1rem;
  text-wrap: nowrap;
  justify-content: space-between;
}

.dropdown-menu li:hover {
  background-color: var(--as-white);
}

.spa-notification {
  display: flex;
  flex-direction: column;
  gap: 0.25rem;
  position: absolute;
  right: 1.5rem;
}

.spa-notification-with-warning {
  position: relative;
}

.spa-notification-warning {
  position: absolute;
  top: -0.5rem;
  right: -0.25rem;
}

.spa-notification .icon path {
  stroke-width: 1.5;
  stroke: var(--as-gray);
  scale: 0.8;
}

.icon.warning-notification-icon path {
  stroke: var(--font-white);
}

.button-wrapper {
  display: flex;
  flex-direction: column;
  gap: 12px;
  color: var(--as-gray);
  justify-content: center;
  align-items: center;
}

.temperature-setting {
  display: flex;
  justify-content: space-between;
  gap: 0.8rem;
  align-items: end;
  color: var(--as-gray);
  padding: 0 0 0.25rem 0rem;
}

.temperature-value {
  font-weight: 500;
  font-size: xx-large;
  color: var(--font-black);
  width: 6rem;
  text-align: right;
}

.current-thumb {
  background-image: linear-gradient(
    to right,
    var(--opacity-max),
    var(--opacity-75)
  );
  position: absolute;
  top: -1.65rem;
  width: 1.2rem;
  height: 1.38rem;
}

.spa-controls-slider .MuiSlider-root {
  border-radius: 0.2rem !important;
  height: 0 !important;
  /* Padding determines thickness of the track */
  padding: 0.55rem 0rem !important;
}

.spa-controls-slider .MuiSlider-rail {
  color: transparent;
}
.spa-controls-slider .MuiSlider-track {
}

.menu-item {
  display: flex;
  justify-content: space-between;
  align-items: center;
  /* border-bottom: solid 0.01rem rgba(0, 0, 0, 0.3); */
}

.menu-item-no-bottom {
  display: flex;
  justify-content: space-between;
  align-items: center;
  border-bottom: solid transparent;
}

:is(.menu-item, .menu-item-no-bottom) * {
  padding: 0 0 0 0.2rem;
  display: flex;
  align-items: center;
  min-width: 3rem;
}

.color-picker {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding:25px;
  background-color: var(--as-white);
  gap: 25px;
  box-shadow: 0px 1px 3px var(--box-shadow);
}

.brightness-slider{
  display: flex;
  align-items: center;
  justify-content: center;
  width:stretch;
  gap:20px;
}

/* Animations: */

@keyframes pulse {
  0% {
    opacity: 0.9;
  }
  50% {
    opacity: 0.7;
  }
  100% {
    opacity: 0.9;
  }
}

@media only screen and (min-width: 1024px) {
  .primary-window {
    align-items: center;
  }

  .menu-list-frame,
  .spa-control-window {
    width: 100%;
  }
}

@media only screen and (max-width: 768px){
  .top-section {
    display: flex;
    flex-direction: column;
    align-items: center;

  }

  .temperature{
    width: 100%;
    flex-direction: row;
    height: 25%;
  }

  .values .current{
    font-size: 10vmin;
  }
  
  .values .current span{
    font-size: 5vmin;
  }
  
  .values .set-point{
    font-size: 5vmin;
  }
  
  .values .set-point span{
    font-size: 5vmin;
  }

  .spa-section{
    width: 100%;
    height: 60%;
  }

  .nav{
    width: 100%;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
  }

  .nav-btn {
    height:100%;
    width: 100%;
    padding: 0;
  }

  .nav-btn p{
    position: absolute;
    display: none;
  }

  .nav-btn .sanitation-icon{
    width: 100%;
  }

  .sanitation-icon#filter-icon {
    width: 100%;
    height: 75%;
  }

  .action-buttons {
    flex-wrap: wrap;
    padding: 0 1rem;
    gap: 1rem;
    height: 100%;
    width: 100%;
  }

  .action-button{
    width:25%;
    height: auto;
  }

  .action-button.electrode{
    height: 100%;
  }

  .nav-btn img#sb-logo{
    width:100%;
    height: auto;
  }

  .arrow-button .MuiSvgIcon-root {
    font-size: 2rem;
  }

  .ampere.mobile{
    display: flex;
    justify-content: center;
    position: absolute;
    bottom: 1rem;
  }

  .ampere.desktop{
    display: none;
  }

}

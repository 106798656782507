.list-container {
  display: flex;
  flex-direction: column;
  width:stretch;
  margin: 32px;
  border-radius: 10px;
  color:var(--font-black)
  }

  .list-header {
    display: flex;
    align-items: flex-end;
    justify-content: space-between;
  }

  .list-summary {
    display: flex;
    gap:5rem;
    padding: 1rem 0.25rem;
    margin-top: 32px;
    color:var(--font-black);
    }

    .list-summary div{
      display: flex;
      flex-direction: column;
  }
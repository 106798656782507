.update-form {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    margin-bottom: 1rem;
}

.markdown-preview {
 padding:1rem;
 max-height: 75vh;
 overflow: auto;
 border: 1px solid var(--border-color3);
}
@import "../../App.css";

.errors-container {
    display: flex;
    flex-direction: column;
    background: var(--as-white);
    width:stretch;
    height:100%;
    margin: 32px;
    margin-top: 96px;
    border-radius: 10px;
    padding: 1rem;
    gap: 1rem;
    color:var(--font-black);
}

    .grids {
        display: flex;
        justify-content: space-between;
        overflow: auto;
    }
        .errors-grid,
        .status-grid{
            display: grid;
            grid-template-rows: repeat(33,1fr);
            width: stretch;
            grid-auto-flow: column;
            overflow: auto;
            column-gap: 10px;
        }

        .errors-grid {
            border-right: 5px solid var(--border-color);
        }

        .grid-header {
            grid-column:1/span 2;
            background: var(--info-card);
            padding: 0 1rem;
            color:var(--as-gray);
            border-bottom: 1px solid var(--border-color);
            font-size: 1.25rem;
        }

        .error-item{
            display: grid;
            grid-template-columns: max-content 6ch 1fr;
            justify-content: start;
            align-items: center;
            gap:1rem;
            padding:0.5rem 1rem;  
            color:var(--disabled-gray) 
        }

        .error-item.has-error{
            color:var(--font-black);
            background: var(--grid-divider);
        }

        .error-item.has-error .label{
            color:var(--as-gray);
        }

        .error-item .name{
            display: flex;
            gap:1rem;
        }

a.stonly-link{
    color:var(--color-danger)
}





@import "../../../assets/scss/variables";
@import "../../../assets/scss/mixins";

.tech-page-container{
    display: flex;
    width:stretch;
    flex-direction: column; 
    padding: 32px 0;
    gap:0.25rem;
    
    @include respond-to(desktop) {
        width:30%;
        transform: scale(1.1);
    }
}

.switch-container {
    background: var(--as-white);
    border-radius: $border-radius;
    padding: 0 1rem;
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin: {
        bottom:0;
    }
}

.coming-soon-text{
    color:var(--font-header);
    margin: 0.25rem 0;
    font-size: 0.9rem;
}
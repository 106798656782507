:root {
  /* as per Arctic Spas design instruction */
  /* background-image: linear-gradient(
    white,
    rgb(236, 236, 236),
    rgb(236, 236, 236),
    rgb(236, 236, 236)
  ); */
  --background-main: rgb(238, 238, 238);
  --pop-over: rgb(250, 250, 250);
  --pure-white: rgb(255, 255, 255);
  
  --arctic-red: rgb(163, 32, 53);
  --as-red: rgb(163, 32, 53);
  --hover-red: rgb(139, 0, 0);
  --font-red: rgb(163, 32, 53);
  --red-font-hover:rgb(139, 0, 0);

  --as-grey: rgb(121, 125, 130);
  --as-gray: rgb(121, 125, 130);
  --as-white:rgb(250, 250, 250);
  --as-black: rgb(31, 31, 32);
  --as-blue: rgb(0, 127, 188);

  --font-black: rgb(68, 68, 68);
  --font-white: rgb(255, 255, 255);
  --title-gray: rgb(184, 184, 184);
  --box-shadow: rgb(180, 180, 180);
  --font-header: rgb(121, 125, 130);
  --wrapper-color: rgb(224, 224, 224);

  --icon-disabled: rgba(121, 125, 130, 0.418);
  --disabled-gray: rgb(210, 210, 210);
  --disabled-grey: rgb(210, 210, 210);
  --disabled-font: rgb(151, 151, 151);
  --disabled-text: rgb(126, 126, 126);

  --border-color: rgb(229, 229, 229);
  --border-color2: rgb(211, 211, 211);
  --border-color3: rgb(156, 156, 156);

  --color-gray: rgba(0, 0, 0, 0.3);
  --color-sucess: rgb(0, 189, 0);
  --color-danger: rgb(255, 0, 0);
  --hover-danger: rgba(255, 0, 0, 0.75);
  --color-warning: rgb(255 152 0);
  --color-offline: rgba(184, 184, 184, 0.729);

  --scrollbar-thumb: rgb(245, 245, 245);
  --scrollbar-hover: rgb(236, 236, 236);

  --info-card: rgb(242, 242, 242);
  --grid-divider: rgb(234, 234, 234);
  --grid-border: rgb(169, 169, 169);

  --opacity-max:rgba(255, 255, 255, 0);
  --opacity-75: rgba(255, 255, 255, 0.75);

  --stemp-line: #f6c141;
  --sptemp-line: #f1932d;
  --htemp-line: #dc050c;
  --p1-line: #1965b0;
  --h1-line: #7bafde;
  --filter-line: #95211b;
  --sbconn-line: #521a13;
  --current-line: #000000;
  --sbvout-line: #999999;
  --sbi1-line: #6f4c9b;
  --sbi2-line: #b58fc2;
  --sbph-line: #4eb265;
  --sborp-line: #a6be54;
}

[data-theme="dark"]{
  --background-main: rgb(0, 0, 0);
  --pop-over:rgb(45,45,45);
  --info-card:rgb(45,45,45);
  --grid-divider: rgb(45,45,45);
  --wrapper-color: rgb(45,45,45);
  --box-shadow: rgb(30, 30, 30);

  --as-red: rgb(183, 36, 61);
  --red-font-hover: rgba(243, 124, 124,0.5);
  --font-red: rgb(243, 124, 124);

  --as-black: rgb(194, 194, 197);
  --as-white:rgb(31,31,31);
  --as-gray: rgb(189, 194, 200);
  --as-gray: rgb(189, 194, 200);
  --as-blue: rgb(128, 215, 255);
  
  --color-sucess: rgb(143, 255, 143);
  --color-danger: rgb(255,25,27);
  --hover-danger: rgba(255,25,27, 0.5);
  --color-warning: rgb(255, 186, 82);
  --color-offline: rgba(250, 250, 250, 0.729);

  --font-black: rgb(229,229,229);
  --font-header: rgba(255, 255, 255, 0.7);
  --font-white: rgb(40, 40, 40);

  --title-gray: rgba(255, 255, 255, 0.7);
  --icon-disabled: rgba(255, 255, 255, 0.4);
  --disabled-text: rgba(255, 255, 255, 0.4);
  --disabled-gray: rgba(255, 255, 255, 0.4);
  --disabled-grey: rgba(255, 255, 255, 0.4);
  --disabled-font: rgba(255, 255, 255, 0.7);

  --color-gray: rgba(255, 255, 255, 0.5);
  --border-color: rgba(255, 255, 255, 0.12);
  --border-color2: rgba(255, 255, 255, 0.12);
  --border-color3: rgba(255, 255, 255, 0.12);
  --grid-border: rgba(255, 255, 255, 0.12);

  --scrollbar-thumb: rgba(255, 255, 255, 0.16);
  --scrollbar-hover: rgba(255, 255, 255, 0.08);

  --sptemp-line: #f1932d;
  --htemp-line: #FC979A;
  --p1-line: #70d9eb;
  --h1-line: #A6C9E8;
  --filter-line: #f0d3a8;
  --sbconn-line: #f0f3c9;
  --current-line: #FFFFFF;
  --sbvout-line: #C2C2C2;
  --sbi1-line: #C6B3DB;
  --sbi2-line: #DECEE4;
  --sbph-line: #8ECD9D;
  --sborp-line: #CBD99B;
}

.App {
  /* text-align: center; */
  color: var(--font-black);
  background: var(--background-main);
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

a {
  color: var(--font-red)
}

input:-webkit-autofill,
input:-webkit-autofill:hover, 
input:-webkit-autofill:focus
input:-webkit-autofill, 
textarea:-webkit-autofill,
textarea:-webkit-autofill:hover
textarea:-webkit-autofill:focus,
select:-webkit-autofill,
select:-webkit-autofill:hover,
select:-webkit-autofill:focus {
  transition: background-color 5000s ease-in-out 0s;
  -webkit-text-fill-color: var(--font-black) !important;
}

.page {
  height: 100vh;
  width: 100vw;
  display: flex;
  align-items: center;
  justify-content: center;
}

.icon path {
  stroke: var(--as-gray);
  stroke-width: 1.5;
}

.icon.orp-count {
  white-space: nowrap;
  position: relative;
}
.icon.orp-count span{
  position: absolute;
  bottom: 0;
}


.icon.orp-count path {
  stroke: var(--as-black);
}

.icon.toggleable path {
  stroke: var(--icon-disabled);
}

.icon.toggleable.active path {
  stroke: var(--as-black);
}

.toggleable:hover,
.pump-icon {
  cursor: pointer;
}

.icon.table-icon path{
  stroke:var(--font-red);
}

.icon.eco-on path{
  stroke:var(--color-sucess);
  fill:var(--color-sucess);
}

.icon.eco-off path{
  stroke:var(--icon-disabled);
  fill:var(--icon-disabled);
}


.icon.eco-off path,
.icon.eco-on path {
  stroke-width: 1;
}

.icon.error-icon.noerrors path{
  stroke:var(--icon-disabled);
}

.icon.error-icon.errors path{
  stroke:var(--color-danger);
}

.icon.error-icon.status path{
  stroke:var(--color-warning);
}


.active path {
  stroke: var(--as-black);
}

.hide-in-mobile {
  display: none;
}

.sub-menu-title {
  width: 100%;
  text-transform: uppercase;
  font-size: medium;
  font-weight: 700;
  color: var(--color-gray);
  padding: 0.3rem;
  display: flex;
  align-items: center;
  justify-content: space-between;
  flex-wrap: wrap;
}

.sub-menu-box {
  min-width: calc(100% - 2rem);
  display: flex;
  flex-direction: column;
  gap: 18px;
  border-radius: 0.3rem;
  background-color: var(--as-white);
  border: solid 1px var(--border-color);
  justify-content: center;
  align-items: center;
  padding: 1rem;
  margin: 0.5rem 0;
}

.sub-menu-switch {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  text-transform: capitalize;
  width: -webkit-fill-available;
  color: var(--as-gray);
}

.disabled {
  color: var(--disabled-gray);
  text-decoration-color: var(--disabled-gray);
}

/* MUI Overrides */
.MuiTable-root td,
.MuiPagination-root button,
.MuiTabs-root button,
.MuiFormControl-root label,
.MuiInputBase-root svg,
.MuiList-root,
.MuiFormGroup-root label,
.MuiInputBase-root textarea {
  color: var(--font-black);
  z-index: 99;
}

.MuiPagination-root button {
  border-color: var(--font-black);
}

.MuiPagination-root button.Mui-selected {
  color: var(--pure-white);
  background: var(--hover-red);
}
.MuiFormControl-root input.MuiInputBase-input,
.MuiInputBase-root .MuiSelect-select {
  color: var(--font-black);
  z-index:98;
}

.MuiPagination-root button.Mui-disabled,
.MuiPagination-root button.Mui-selected.Mui-disabled,
.MuiFormGroup-root label span.MuiTypography-root.Mui-disabled,
.MuiFormControl-root input.MuiInputBase-input.Mui-disabled,
.MuiFormLabel-root-disabled {
  color: var(--disabled-text);
}

.MuiFormGroup-root label span.Mui-disabled {
  color: var(--icon-disabled);
}
.MuiPagination-root button.Mui-selected.Mui-disabled{
  background: var(--icon-disabled);
}

.MuiTable-root th{
  color:var(--title-gray);
  background:var(--as-white);
  font-size:1rem
}

.MuiTable-root th span:hover,
.MuiTable-root th .MuiButtonBase-root svg{
  color:var(--title-gray);
  fill:var(--hover-red);
}

.MuiTable-root th span.Mui-active{
  color:var(--hover-red);
}

.MuiInputBase-root fieldset,
.MuiList-root {
  background:var(--pop-over);
}

.MuiFormControl-root label.Mui-disabled,
.MuiInputBase-root svg.Mui-disabled{
  color: var(--disabled-text);
}

.MuiInputBase-root div.Mui-disabled,
.MuiInputBase-input.MuiFilledInput-input.Mui-disabled,
.MuiTextField-root.Mui-disabled{
  background: var(--disabled-input);
  z-index:97;
}

.MuiInputLabel-formControl.Mui-error.MuiInputLabel-root,
p.MuiFormHelperText-root.Mui-error,
div.MuiInputBase-root.MuiOutlinedInput-root.Mui-error fieldset{
  color: var(--color-danger);
  border-color: var(--color-danger);
}

/* MUI Overrides */
.button {
  background-color: var(--as-red);
  color:var(--pure-white);
  padding: 0.25rem 1rem;
  border-radius: 10px;
  text-align: center;
  overflow-x: hidden;
}

.button:hover {
  background-color: var(--hover-red);
  cursor: pointer;
  user-select: none;
}

.button:active {
  transform: scale(0.95);
}

.button:disabled {
  background: var(--disabled-gray);
  color: var(--disabled-font);
}

.page-wrapper {
  height:100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.page-wrapper.top{
  height:100%;
  margin-top: 15vh;

}

.menu-button a{
  display: flex;
  align-items: center;
  gap: 0.5rem;
  color: var(--as-gray);
  text-decoration: none;
  fill: var(--as-gray);
}

.clickable-icon{
  color:var(--font-red);
  text-decoration: underline;
}

.clickable-icon:hover {
    cursor: pointer;   
}

.clickable-icon:active {
    transform: scale(0.95);
}

.check{
  color:var(--color-sucess);
}

.ex {
  color:var(--color-danger);
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

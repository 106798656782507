.details-container {
    display: flex;
    flex-direction: column;
    background: var(--as-white);
    width: stretch;
    height: 100%;
    margin: 32px;
    margin-top: 96px;
    border-radius: 10px;
    color: var(--font-black);
    padding: 1rem;
    gap: 1rem;
    position: relative;
}

.absolute-icon {
    position: absolute;
    right: 1rem;
    user-select: none;
}

.edit-group {
    display: flex;  
    user-select: none;
    position:absolute;
    right:1rem;
    top:8.5rem;
}

.edit-button {
    color: var(--font-red);
    background: none;
    border: none;
    font-size: 1rem;
}

.edit-button:hover {
    color: var(--red-font-hover);
    cursor: pointer;
    user-select: none;
}

.edit-button:active {
    transform: scale(0.95);
}


.edit-button:disabled,
.edit-button:disabled:hover,
.edit-button:disabled:active {
    cursor: default;
    color: var(--disabled-font);
    transform: none;
}


.corner-icons {
    position: absolute;
    right: 1rem;
    user-select: none;
    display: flex;
    gap: 1rem;
    transform: scale(1.25);
}

.details-header {
    display: flex;
    width: stretch;
    gap: 2rem;
    position: relative;
}

.card-container {
    background: var(--info-card);
    padding: 1rem;
    border-radius: 10px;
    text-overflow: ellipsis;
    white-space: nowrap;
}

.grid-item.set {
    display: flex;
    gap: 1rem;
}

.grid-item.grid-values {
    color: var(--as-gray);
}

.grid-item.grid-values div input{
    width: 3rem;
}

.card-container.user {
    display: flex;
    position: relative;
    flex-direction: column;
    width: 15vw;
}

.user-status-container {
    display: flex;
    gap: 1rem;
}

.card-container.spa {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
}

.spa-basic-info {
    display: flex;
    flex-direction: column;
    position: relative;
}

.info {
    display: flex;
    align-items: center;
    gap: 0.25rem;
}

.spa-basic-info a {
    color: var(--font-red)
}

.spa-basic-info a:hover {
    color: var(--red-font-hover);
}

.spa-basic-info a:active {
    color: var(--font-black);
}

.details-grid {
    display: grid;
    grid-template-columns: repeat(10, auto);
    white-space: nowrap;
    overflow: auto;
    border: 1px solid var(--as-white);
    border-right: none;
    position: relative;
}

.grid-header-row {
    padding: 0 0.25rem;
    color: var(--as-gray)
}

.grid-header-row .live-amps {
    border: none;
    position: absolute;
    overflow-x: hidden;
}

.details-grid div {
    color: var(--as-gray);
    border-right: 1px solid var(--as-white);
    padding: 0 1rem;
    padding-bottom: 0.25rem;
}

.details-grid .active {
    color: var(--font-black);
}

.details-grid div:nth-child(n+26):nth-child(-n+34),
.details-grid div:nth-child(n+46):nth-child(-n+54),
.details-grid div:nth-child(n+66):nth-child(-n+74),
.details-grid div:nth-child(n+86):nth-child(-n+94),
.details-grid div:nth-child(n+106):nth-child(-n+114),
.details-grid div:nth-child(n+136):nth-child(-n+144),
.details-grid div:nth-child(n+156):nth-child(-n+164),
.details-grid div:nth-child(n+176):nth-child(-n+184) {
    background: var(--info-card);
}

.details-grid div:nth-child(10n-5),
.grid-header-row {
    background: var(--grid-divider);
}

.details-grid div:nth-child(10n-4),
.details-grid div:nth-child(10n-3),
.details-grid div:nth-child(10n+2),
.grid-header-row {
    border-left: 2px solid var(--grid-border);
}

.grid-header-row:first-child {
    border-left: none;
}

.details-grid div.title {
    text-align: right;
    color: var(--font-black);
}

.details-grid div.config {
    text-align: center;
}

.details-grid div:hover {
    cursor: default;
}

.labelled-cell {
    display: flex;
    justify-content: space-between;
}

.firmware-update {
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 0.75rem;
}

.firmware-update strong {
    text-transform: capitalize;
    white-space: nowrap;
}

.update-info {
    display: flex;
    gap: 1rem;
    align-items: center;
    justify-content: center;
}

.progress-container {
    width: 300px;
    background-color: #f3f3f3;
    border-radius: 5px;
    overflow: hidden;
    position: relative;
    height: 15px;
}

.progress-bar {
    height: 100%;
    width: 0;
    background-color: #4caf50;
    text-align: center;
    line-height: 30px; /* Center text vertically */
    color: white;
    font-weight: bold;
    transition: all 1s ease;
}

.progress-text {
    position: absolute;
    top: -1px;
    left: 50%;
    transform: translateX(-50%);
    font-size: 12px;
}

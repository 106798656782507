@import "variables";
@import "mixins";

.cardContainer{
    background: var(--as-white);
    border-radius: $border-radius;
    border:none;
    padding:0.25rem 1.25rem;
    width: 100%;
    color: var(--font-black);
    font-size: 1rem;
    &:hover{
        cursor: pointer;
        user-select: none;
    }

    &:disabled{
        cursor:default;
        background: var(--icon-disabled);
        color: var(--disabled-text);
    }
}

.divider{
    border: 1px solid var(--icon-disabled);
    opacity: 24%;
    margin:0;
}

.upperContent,
.lowerContent {
    display:flex;
    justify-content: space-between;
    align-items: center;
}


.spaStatus {
    display:flex;
    justify-content: center;
    align-items: center;
    gap:10px;
}

.deleteButton{
    stroke:var(--color-danger);
    border: none;
    width:20px;
    height:20px;

    &:hover{
        stroke:var(--as-red)
    }
}

.isMovedFlag {
    background-color: var(--as-red);
    color: var(--pure-white);
    padding: 0.25rem;
    border-radius: $border-radius;
    filter: grayscale(0.25)
}


.lowerContent,
.model,
.offlineName {
    color:var(--font-header);
}

.editName {
    text-decoration: underline;
    &:hover{
        color:var(--as-red);
    }

    &:active{
        color:var(--hover-red);
    }
}
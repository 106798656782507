.role-checkboxes {
    display: flex;
    flex-direction: column;
    gap: 1rem;
    font-size: 1.25rem;
    padding: 2rem 1rem;
    border-top: 1px solid var(--border-color);
    color: var(--font-black);
    user-select: none;
}

.edit-modal-input {
    display: flex;
    flex-direction: column;
    padding:1rem;
    gap: 1.25rem;
}

.edit-modal-input .toggles {
    display: flex;
    justify-content: space-between;
    user-select: none;
    margin-bottom: 2rem;
}

.edit-modal-input .edit-header {
    color: var(--as-gray);
    font-size: 1.25rem;
    font-weight: 550;
    border-bottom: 1px solid var(--as-gray);
}

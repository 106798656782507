@import "variables";
@import "mixins";

.modal {
    display: flex;
    flex-direction: column;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    min-width:35vw;
    background: var(--as-white);
    padding: 1rem;
    border-radius: $border-radius;
    align-content: center;
}

.header,
.body {
    text-align: center;
}

.header {
    font-weight: 500;
    font-size: large;

    &.ellipsis {
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
        max-width: 320px;
        margin: auto;
    }
}

.body {
    color: var(--as-gray);
    margin: {
        bottom:1.25rem;
    };
}

.updateBody {
    display: flex;
    justify-content: center;
    align-items: center;
    color: var(--as-gray);
    margin: {
        bottom:1.25rem;
    };
}

.forms{
    display: flex;
    flex-direction: column;
    align-items: flex-start;
}

.formLabel{
    font-weight: 500;
    color: var(--as-gray);
}

.textInput {
    width:stretch;
    padding:0.5rem;
    margin: 10px 0 10px 0;
}

.buttons {
    display: grid;
    grid-template-columns: auto auto;
    justify-content: space-between;
    justify-items: center;
}

.delete,
.cancel,
.confirm {
    border: none;
    padding:0.5rem 1rem;
    border-radius: $border-radius;
    width: 5rem;
    font-size: 1rem;
    padding:5px;
    margin:0 5px;
    &:hover{
        cursor:pointer;
    }

}

.cancel {
    background: var(--as-white);
    color: var(--font-red);
    &:hover{
        color:var(--red-font-hover);
    }
    &:active{
        color:var(--red-font-hover);
    }
}

.confirm {
    color:var(--font-white);
    background: var(--font-red);
    &:hover{
        background:var(--red-font-hover);
    }
    &:active{
        background:var(--red-font-hover);
    }
}

.delete {
    color:var(--pure-white);
    background:var(--color-danger);
    &:hover{
        background:var(--hover-danger)
    }
    &:active{
        background:var(--hover-danger)
    }
}
.page.sanitation{
    display: flex;
    flex-direction: column;
    width:100vw;
    height:100vh;
    user-select: none;
    overflow: hidden;
  }
  
  .controls-page-container.sanitation {
    flex-direction: row;
    padding: 0 2rem;
    gap:1rem;
  }

  .sanitation-setting {
    width:100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    text-align: center;
    justify-content: space-between;
  }

  .settings-header  {
    height: 10%;
    font-size: 1.5vmax;
    font-weight: bolder;
  }

  .status-icon  {
    height: 10%;
  }

  .status-avatar {
    height: 100%;
  }

  /* Ozone */
  .status-avatar.default#Ozone{
    background: url('../../../assets/images/Ozone/default.png');
    background-position: center;
    background-size:contain;
    background-repeat: no-repeat;
  }

  .status-avatar.selected#Ozone{
    background: url('../../../assets/images/Ozone/active.png');
    background-position: center;
    background-size:contain;
    background-repeat: no-repeat;
  }

  /* Onzen */
  .status-avatar.default#Onzen{
    background: url('../../../assets/images/Onzen/default.png');
    background-position: center;
    background-size:contain;
    background-repeat: no-repeat;
  }

  .status-avatar.selected#Onzen{
    background: url('../../../assets/images/Onzen/active.png');
    background-position: center;
    background-size:contain;
    background-repeat: no-repeat;
  }

  .status-text  {
    height: 10%;
    font-size: 1.5vmax;
    color: var(--as-gray);
  }

  .controls  {
    height: 50%;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    position: relative;
  }

  .summary  {
    height: 15%;
    font-size: 1.5vmax;
    color: var(--as-gray);
    text-align: center;
    margin-bottom: 1rem;
  }

  .sanitation.value{
    width: 25%;
    font-size: 2vmax;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    flex-wrap: nowrap;
    white-space:nowrap;
  }

  .sanitation.button{
    width: 100%;
  }

  @media only screen and (max-width: 768px){
    .controls-page-container.sanitation {
      flex-direction: column;
      padding: 0;
      justify-content: space-between;
    }
    .status-icon  {
      height: 15%;
    }

    .settings-header{
      font-size: 2vmax;
    }

    .status-text{
      font-size: 2vmax;
    }

    .sanitation.value{
      width: 50%;
      font-size: 3vmax;
    }

    .sanitation.value .units {
      font-size: 1.5vmax;
    }
    
    .summary{
      margin-bottom: 0;
      font-size: 2.5vmax;
    }

  
    }
  


  

.modal-form {
    display: flex;
    flex-direction: column;
    align-items: center;
    min-width: 25rem;
}

.modal-form hr {
    width: stretch;
}

.unread {
    font-weight: bold;
    color:red;
}

.edit-comment {
    font-size: 1rem;
    border:none;
    border-radius: 10px;
    padding:0.5rem;
    user-select: none;
    white-space:nowrap;
    z-index: 100;
    color:var(--font-red);
    background: none;
    position:absolute;
    top:10px;
    right:10px;
}

.edit-comment:hover {
    cursor: pointer;
    color:var(--red-font-hover);
}
.user-search-container {
    margin: 10px 0 10px 0;
    display: flex;
    gap:10px;
}

.user-search-container input,
.user-search-container button{
    padding: 0.5rem;
}

.user-search-container input{
    width: 75%;
}
.user-search-container button{
    width: 25%;
}

.select-container {
    display: grid;
    grid-template-columns: 1fr;
    width:stretch;
    align-items: center;
    gap:10px;
}

.no-users {
    align-items: center;
    justify-content: center;
    text-align: center;
}

.command-row {
    display: flex;
    gap:10px;
    text-transform: capitalize;
}

.user-select {
    display: flex;
    flex-direction: column;
    text-align: start;
    max-height:150px;
    overflow-y: auto;
    border: 1px solid var(--border-color);
    text-wrap:nowrap;
    scrollbar-gutter: stable both-edges;
}

.user-select.commands{
    max-height:300px;
}

.user-select div{
    border-bottom: 1px solid var(--border-color);
    padding: 10px 0;
}

.sub-menu{
    display: flex;
    font-size: 12px;
    gap: 12px;
    border-bottom: 0 !important;
    padding: 0!important;
    margin-left: 12px;
}

.sub-menu-item{
    border-bottom: 0 !important;
    padding: 0!important;
    display: flex;
    align-items: center;
    gap: 2px;
}

.sub-menu-item span {
    margin-top: 3px;
}

.user-select span{
    padding: 5px 0;
}